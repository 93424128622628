<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Active Status 🚀
        </h2>
        <p class="mb-3">
          {{ error }}
        </p>

        <b-img
          fluid
          :src="imgUrl"
          alt="Coming soon page"
        />
      </div>
    </div>
  </div>
<!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import Password from '@/js/password'
import moment from 'moment'
import Constants from '@/js/constant'

export default {
  components: {
    VuexyLogo,
    BLink,
    BImg,
  },
  data() {
    return {
      isActive: false,
      activateStatusRequest: {
        token: '',
        date: '',
      },
      error: '',
      downImg: require('@/assets/images/pages/coming-soon.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  created() {
    // this.$ticker._tickList.splice(0)
    let dns = window.location.hostname
    dns = dns.substring(dns.indexOf('.') + 1)
    dns = dns.substring(0, dns.indexOf('.'))
    this.activateStatusRequest.token = this.$route.params.token
    this.activateStatusRequest.date = Constants.getEprmMonthDateFromString(moment())
    this.activateStatusRequest.dns = dns
    Password.verifyStatusToken(this)
  },
  destroyed() {
    // this.$ticker._tickList.splice(0)
  },
  methods: {
    loginSubmit() {
      this.$validator.validate().then(valid => {
        if (valid) {
          Password.forgotpassword(this)
        }
      })
    },
    setStatusActivated(data) {
      this.$refs.showLoading = false
      this.$router.push('/login').catch(() => {})
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    setStatusNotActivated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    creatError(message) {
      this.$dialog.alert(message)
    },
    created(message) {
      this.$dialog.alert(message)
      window.location.hash = '/login'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
